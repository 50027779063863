import React from "react"
import { graphql } from "gatsby"
import { FormattedMessage } from "react-intl"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import slugify from "react-slugify"
import * as Sentry from "@sentry/react"

import Layout from "../layouts"

import { smartReplacements } from "../utils/text-properties"

import LocalizedLink from "../components/LocalizedLink"
import PlaceholderCookieConsentReset from "../components/PlaceholderCookieConsentReset"
import PlaceholderEmailAddress from "../components/PlaceholderEmailAddress"
import PlaceholderPostalAddress from "../components/PlaceholderPostalAddress"
import PlaceholderVideo from "../components/PlaceholderVideo"

const StaticPage = props => {
  const {
    pageContext: { locale },
    data: {
      allContentfulPage: { nodes: pages },
    },
  } = props

  /**
   * get localized page
   * @param {Array} pages
   * @param {String} locale
   * @returns
   */
  const getLocalizedPage = (pages, locale) => {
    return pages.find(page => page.node_locale === locale)
  }

  const localizedPage = getLocalizedPage(pages, locale)

  // see: https://www.contentful.com/developers/docs/tutorials/general/rich-text-and-gatsby/
  // and: https://github.com/contentful/rich-text/tree/master/packages/rich-text-react-renderer
  /**
   * The `renderNode` keys should be one of the following `BLOCKS` and `INLINES` properties:
   *    - BLOCKS
   *      - DOCUMENT
   *      - PARAGRAPH
   *      - HEADING_1 ... HEADING_6
   *      - UL_LIST, OL_LIST, LIST_ITEM
   *      - QUOTE
   *      - HR
   *      - EMBEDDED_ENTRY, EMBEDDED_ASSET
   *    - INLINES
   *      - EMBEDDED_ENTRY
   *      - HYPERLINK, ENTRY_HYPERLINK, ASSET_HYPERLINK
   *
   * The `renderMark` keys should be one of the following `MARKS` properties:
   *    - BOLD, ITALIC, UNDERLINE, CODE
   *
   * The `renderText` callback is a function that has a single string argument and returns a
   * React node. Each text node is evaluated individually by this callback. A possible use case
   * for this is to replace instances of `\n` produced by `Shift + Enter` with `<br />` React
   * elements.
   *
   * The `scrollMarginTop` property ensures that the headline is in the field of view.
   * More details: https://gomakethings.com/how-to-prevent-anchor-links-from-scrolling-behind-a-sticky-header-with-one-line-of-css/
   */

  /**
   * inject an anchor using `slugify` into each heading
   * @param {String} node
   * @returns Object
   */
  const injectHeaderAnchor = node => {
    const slug = slugify(node.content[0].value)
    const CustomHeading = `h${node.nodeType.substr(-1)}`
    return (
      <CustomHeading id={slug} style={{ scrollMarginTop: "6em" }}>
        {node.content[0].value}
      </CustomHeading>
    )
  }

  const renderOptions = {
    renderNode: {
      [INLINES.ENTRY_HYPERLINK]: node => {
        return (
          <LocalizedLink to={`/${node.data.target.pageSlug}`}>
            {node.content[0].value}
          </LocalizedLink>
        )
      },
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        if (node.data.target.component === "Cookie Consent Reset") {
          return <PlaceholderCookieConsentReset />
        }
        if (node.data.target.component === "Email Address") {
          return <PlaceholderEmailAddress />
        }
        if (node.data.target.component === "Postal Address") {
          return <PlaceholderPostalAddress />
        }
        if (node.data.target.component === "Video") {
          const videoTitle = node.data.target.videoTitle || null
          const videoUrl = node.data.target.videoUrl || null
          return <PlaceholderVideo title={videoTitle} url={videoUrl} />
        }
        return <React.Fragment></React.Fragment>
      },
      [BLOCKS.HEADING_1]: injectHeaderAnchor,
      [BLOCKS.HEADING_2]: injectHeaderAnchor,
      [BLOCKS.HEADING_3]: injectHeaderAnchor,
    },
    renderText: text => {
      return smartReplacements(text, locale)
        .split("\n")
        .reduce((children, textSegment, index) => {
          return [...children, index > 0 && <br key={index} />, textSegment]
        }, [])
    },
  }

  // extract headings for table of contents
  let headings = {}
  try {
    headings = JSON.parse(localizedPage.pageContent.raw)
      .content.filter(n => {
        switch (n.nodeType) {
          case BLOCKS.HEADING_1:
          case BLOCKS.HEADING_2:
          case BLOCKS.HEADING_3:
            return true
          default:
            return false
        }
      })
      .reduce((acc, cur) => {
        acc[cur.content[0].value] = slugify(cur.content[0].value)
        return acc
      }, {})
  } catch (e) {
    Sentry.captureException(e)
  }

  return (
    <Layout title={localizedPage.pageTitle} description={""}>
      <section className="section has-background-paper">
        <div className="container">
          <h1 className="title mt-6">{localizedPage.pageTitle}</h1>
        </div>
      </section>
      <section className="section">
        <div className="container content is-max-desktop">
          {localizedPage.tableOfContents === true && Object.keys(headings).length > 0 && (
            <React.Fragment>
              <h4>
                <FormattedMessage id={"general.table-of-contents"} />
              </h4>
              <ul className="mb-6">
                {Object.entries(headings).map(heading => (
                  <li key={heading[0]}>
                    <a href={`#${heading[1]}`}>{heading[0]}</a>
                  </li>
                ))}
              </ul>
            </React.Fragment>
          )}
          {localizedPage.pageContent &&
            renderRichText(localizedPage.pageContent, renderOptions)}
        </div>
      </section>

      {/* <pre>{JSON.stringify(pages, null, 2)}</pre> */}
    </Layout>
  )
}

export default StaticPage

export const StaticPageQuery = graphql`
  query ($contentfulId: String!) {
    allContentfulPage(filter: { contentful_id: { eq: $contentfulId } }) {
      nodes {
        node_locale
        pageContent {
          raw
          references {
            ... on ContentfulPage {
              contentful_id
              pageTitle
              pageSlug
              # pageType
            }
            ... on ContentfulPlaceholder {
              contentful_id
              component
              videoTitle
              videoUrl
            }
          }
        }
        pageTitle
        tableOfContents
      }
    }
  }
`
